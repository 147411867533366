import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Split from '../components/Split';

import styles from './jwdm.module.css';

const JWDMPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="JWDM">
        <Split reverse>
          <Img fluid={data.image.childImageSharp.fluid} />
          <div>
            <p>
              After much anticipation, we were thrilled to officially launch the
              limited edition JWDM Cabernet in June this year. Only 649 bottles
              were produced in 2015 from a selection of our oldest Cabernet
              sauvignon vines, planted in 1971 by founder Dr John William Dyer
              Middleton. 2015 also commemorated 40 years of winemaking at Mount Mary, a
              significant milestone in the history of our treasured site.
            </p>
            <p>
              With an overwhelming response and the extremely limited release, the
              wine has sold out. We trust those that requested a bottle were
              successful and very much look forward to hearing about the special
              occasions you share with family and good friends when you open your
              JWDM bottles in the years to come.
            </p>
            <p>
              Thank you to everyone who joined us to celebrate the launch at Vue de
              Monde. It truly was a magical evening and one that will be remembered
              in the Mount Mary history book for many years to come.
            </p>
          </div>
        </Split>
      </Section>
      <Section>
        <div className={styles.imageRow}>
          <Img fluid={data.image2.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image3.childImageSharp.fluid} className={styles.image} />
        </div>
        <div className={styles.imageRow}>
          <Img fluid={data.image4.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image5.childImageSharp.fluid} className={styles.image} />
        </div>
      </Section>
      <Pagination
        forwardLink="/vintage"
        backLink="/vineyard"
      />
    </Layout>
  );
};

JWDMPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "JWDM.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "JWDM2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "JWDM4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "JWDM1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "JWDM3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default JWDMPage;
